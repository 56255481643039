import React, { useEffect } from "react"
import Markdown from "react-markdown"
import ButtonLink from "../elements/button-link"
import Image from "../image"
import { getButtonAppearance } from "@/utils/button"
import CustomLink from "../elements/custom-link"

import { gsap, Power3, Bounce } from "gsap"
// import { TimelineLite, Power3 } from "gsap"
// gsap.from(".heading", { y: 1280, ease: Power3.easeOut }, "Start")

// .from(".heading-1", { duration: 1, y: "-100%", ease: "bounce", delay:1 })
const Hero = ({ data }) => {
  return (
    <main
      className="container flex flex-col md:flex-row items-center justify-between py-12"
      style={{
        /* BackgroundPrimary */
        background: "#EFF1FE",
        borderRadius: "16px 16px 512px 16px",
      }}
    >
      {/* Left column for content */}
      <div className="flex-1 sm:pr-8 md:p-32">
        {/* Hero section label */}
        <p className="hero-title text-evp-primary max-w-sm heading-1">
          {data.label}
        </p>
        {/* Big title */}
        <h1 className="title mt-2 sm:mt-0 mb-4 sm:mb-2 hero-title heading-2">
          {data.title}
        </h1>
        {/* Description paragraph */}
        <hr
          className="my-4 md:my-6 hr"
          style={{ borderTop: "8px solid var(--evp-primary)", width: "64px" }}
        ></hr>
        <p className="text-xl mb-6 sub-text text-evp-secondary paragraph">
          {data.description}
        </p>
        {/* Buttons row */}
        <div className="flex flex-row flex-wrap gap-4 button">
          {data.buttons.map((button, i) =>
            button.buttonImage ? (
              <CustomLink link={button} key={i}>
                {button.buttonImage && (
                  // <Image placeholder="none" media={button.buttonImage} />
                  <img
                    src={
                      i == 0
                        ? "https://storage.googleapis.com/ev-cms-uploads/button_appstore_91af1c944b/button_appstore_91af1c944b.png"
                        : "https://storage.googleapis.com/ev-cms-uploads/button_googleplay_3bc17ba607/button_googleplay_3bc17ba607.png"
                    }
                    alt="button-googleplay"
                    border="0"
                  />
                  //                   https://ibb.co/ZBXT8Lj
                  // https://ibb.co/zZT59xv
                  // https://ibb.co/nRsKQy5
                )}
              </CustomLink>
            ) : (
              <ButtonLink
                button={button}
                appearance={getButtonAppearance(button.type, "light")}
                key={button.id}
              />
            )
          )}
        </div>
        {/* Small rich text */}
        <div className="text-base md:text-sm mt-4 sm:mt-3 rich-text-hero">
          <Markdown source={data.smallTextWithLink} />
        </div>
      </div>
      {/* Right column for the image */}
      {/* <Image
        media={data.picture}
        className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
      /> */}
      <img
        src={
          "	https://storage.googleapis.com/ev-cms-uploads/mobile_full_f25e157507/mobile_full_f25e157507.png"
        }
        className="flex-shrink-0 object-contain w-full md:w-6/12 mt-6 md:mt-0 max-w-md"
      ></img>
    </main>
  )
}

export default Hero
