import React from "react"
import PropTypes from "prop-types"
import Markdown from "react-markdown"
import "./rich-text.css"

const RichText = ({ data }) => {
  return (
    <div className="container">
      <div>
        <h1 className="hero-title pt-10">{data.Title}</h1>
        {/* Description paragraph */}
        <hr
          className="my-4 md:my-6"
          style={{ borderTop: "8px solid var(--evp-primary)", width: "64px" }}
        ></hr>
      </div>
      <Markdown source={data.content} />
    </div>
  )
}

RichText.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
  }),
}

export default RichText
